<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        <router-link to="/service" class="back-button"
          ><i class="el-icon-back"></i
        ></router-link>

        <span
          v-if="$route.name === 'addService' || $route.name === 'editService'"
        >
          Үйлчилгээ нэмэх
          <div class="editField">
            <el-row :gutter="12">
              <el-col :span="16" :offset="4">
                <div class="panel">
                  <el-form
                    :model="service"
                    :rules="rules"
                    ref="service"
                    label-width="220px"
                    class="demo-ruleForm"
                  >
                    <el-form-item label="Нэр" prop="name_mon">
                      <el-input
                        style="width: 250px"
                        size="mini"
                        v-model="service.name_mon"
                        placeholder="Үйлчилгээний нэр оруулна уу"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Англи нэр" prop="name_eng">
                      <el-input
                        style="width: 250px"
                        size="mini"
                        v-model="service.name_eng"
                        placeholder="Үйлчилгээний англи нэр оруулна уу"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Төрөл">
                      <el-select v-model="service.type">
                        <el-option
                          v-for="item in [
                            { label: 'Хуудас', value: 'defR' },
                            { label: 'Үсрэх', value: 'pop' },
                            { label: 'Линк', value: 'link' }
                          ]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      v-if="service.type != 'link'"
                      label="Үсрэх хуудас"
                    >
                      <el-select v-model="service.route">
                        <el-option
                          v-for="item in [
                            { label: 'Харилцагч', value: 'RestaurantScreen' }
                          ]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      v-if="service.type === 'link'"
                      label="Линк"
                      prop="link"
                    >
                      <el-input
                        style="width: 250px"
                        size="mini"
                        v-model="service.link"
                        placeholder="Линк оруулна уу"
                      >
                      </el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-switch
                        v-model="service.is_show"
                        active-text="Харагдуулах"
                        inactive-text="Нуух"
                      >
                      </el-switch>
                    </el-form-item>
                    <el-form-item label="Үйлчилгээний төлөв" prop="status">
                      <el-radio
                        v-model="service.status"
                        label="active"
                        border
                        size="mini"
                        >Идэвхитэй</el-radio
                      >
                      <el-radio
                        v-model="service.status"
                        label="inactive"
                        border
                        size="mini"
                        >Идэвхгүй</el-radio
                      >
                    </el-form-item>
                    <el-form-item label="Хүргэх хугацаа" prop="promised_time">
                      <el-input
                        style="width: 250px"
                        size="mini"
                        type="number"
                        v-model="service.promised_time"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="Баннер хоорондох зай"
                      prop="promised_time"
                    >
                      <el-input-number
                        size="mini"
                        v-model="service.bannerSkip"
                        :min="1"
                      ></el-input-number>
                    </el-form-item>
                    <el-form-item label="Эрэмбэ" prop="sort">
                      <el-input
                        style="width: 250px"
                        size="mini"
                        type="number"
                        v-model="service.sort"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Баджетийн төрөл">
                      <el-select
                        v-model="service.badge_type"
                        placeholder="Баджетийн төрөл сонгоно уу"
                      >
                        <el-option label="Зураг" value="image"></el-option>
                        <el-option label="Текст" value="text"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      v-if="service.badge_type === 'text'"
                      label="Баджетийн текст"
                      prop="badge"
                    >
                      <el-input
                        style="width: 250px"
                        size="mini"
                        v-model="service.badge.title"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      label="Баджетийн текст англи"
                      v-if="service.badge_type === 'text'"
                    >
                      <el-input
                        style="width: 250px"
                        size="mini"
                        v-model="service.badge.title_eng"
                        placeholder="Англи баджет оруулна уу"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      v-if="service.badge_type === 'text'"
                      label="Баджет өнгө"
                    >
                      <el-color-picker v-model="service.badge.badgeColor" />
                    </el-form-item>
                    <el-form-item
                      label="Баджетийн зураг"
                      prop="badge"
                      v-if="service.badge_type === 'image'"
                    >
                      <el-input
                        style="width: 250px"
                        size="mini"
                        placeholder="Баджетийн зураг"
                        v-model="service.badge.image"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      v-if="service.badge_type === 'image'"
                      label="Баджетийн зурагны байрлал"
                      prop="badge"
                    >
                      <el-select
                        v-model="service.badge.position"
                        placeholder="Баджетийн зурагны байрлал сонгоно уу"
                      >
                        <el-option
                          label="Зүүн дээр"
                          value="leftTop"
                        ></el-option>
                        <el-option
                          label="Зүүн доор"
                          value="leftBottom"
                        ></el-option>
                        <el-option
                          label="Баруун дээр"
                          value="rightTop"
                        ></el-option>
                        <el-option
                          label="Баруун доор"
                          value="rightBottom"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="Зураг" prop="image">
                      <el-input
                        style="width: 250px"
                        size="mini"
                        placeholder="зураг"
                        v-model="service.image"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Тайлбар гарчиг" prop="message.title">
                      <el-input
                        style="width: 250px"
                        size="mini"
                        placeholder="Тайлбар гарчиг"
                        v-model="service.message.title"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Тайлбар дэлгэрэнгүй" prop="message">
                      <el-input
                        style="width: 250px"
                        size="mini"
                        placeholder="тайлбар дэлгэрэнгүй"
                        v-model="service.message.message"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="Шинж чанар нэмэх">
                      <el-checkbox v-model="service.is_property_added">
                        Шинж чанар нэмэх
                      </el-checkbox>
                    </el-form-item>
                    <div
                      v-if="service.is_property_added === true"
                      class="demo-input-size"
                    >
                      <div class="text-center">
                        <el-button
                          @click="addPropertyRow()"
                          size="mini"
                          type="primary"
                          circle
                          style="width: 3%;"
                          >+</el-button
                        >
                      </div>
                      <el-table
                        :data="service.properties"
                        style="width: 100%; padding-left:10%;"
                        max-height="350"
                      >
                        <el-table-column prop="name" label="Нэр" width="150">
                          <template slot-scope="scope">
                            <el-input
                              size="small"
                              placeholder="Порц"
                              style="text-align:center"
                              v-model="scope.row.name"
                              controls-position="right"
                            ></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column prop="value" label="Утга" width="150">
                          <template slot-scope="scope">
                            <el-input
                              size="small"
                              placeholder="1-2хүн"
                              style="text-align:center"
                              v-model="scope.row.value"
                            ></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column label="Үйлдэл" width="100">
                          <template slot-scope="scope">
                            <el-button
                              @click="deletePropertyRow(scope.$index)"
                              type="text"
                              size="mini"
                            >
                              Устгах
                            </el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                    <br />
                    <el-form-item>
                      <el-button type="primary" @click="save()"
                        >Нэмэх</el-button
                      >
                      <el-button
                        :to="{ path: '/banner' }"
                        @click="closeDialog()"
                        >Буцах</el-button
                      >
                    </el-form-item>
                  </el-form>
                </div>
              </el-col>
            </el-row>
          </div>
        </span>
      </h3>
    </div>
  </div>
</template>
<script>
import service from "@/helpers/services.js";
// import Base64Upload from "vue-base64-upload";
export default {
  components: {
    // Base64Upload
  },
  created() {
    if (this.$route.name === "editBanner") {
      this.getBannerId(this.$route.query.id);
    } else if (this.$route.name === "addService") {
      this.$notify({
        title: "Үйлчилгээний мэдээлэл нэмэх хуудас",
        message: "Мэдээллээ бүрэн гүйцэт оруулна уу",
        type: "info"
      });
    }
  },
  data() {
    return {
      loadUpload: false,
      imageNull: "none",
      bannerDetail: {},
      service: {
        badge: {
          title: "Шинэ",
          badgeColor: "#FFB500",
          position: "leftTop",
          image: ""
        },
        is_show: false,
        type: "defR",
        status: "inactive",
        promised_time: 60,
        sort: 10,
        route: "RestaurantScreen",
        image: "",
        message: {
          title: " ",
          message: ""
        },
        bannerSkip: 1,
        link: "",
        properties: [],
        is_property_added: false
      },
      rules: {
        name_mon: [
          {
            required: true,
            message: "Та үйлчилгээний нэрээ оруулна уу",
            trigger: "blur"
          },
          {
            min: 3,
            message: "Хамгийн багадаа 3 үсэг оруулна уу",
            trigger: "blur"
          }
        ],
        name_eng: [
          {
            required: true,
            message: "Та үйлчилгээний англи нэрийг оруулна уу",
            trigger: "change"
          }
        ],
        promised_time: [
          {
            required: true,
            message: "Хүргэх хугацааг оруулна уу",
            trigger: "change"
          }
        ],
        sort: [
          {
            required: true,
            message: "Эрэмбэлэх тоог оруулна уу",
            trigger: "change"
          }
        ],
        badge: [
          {
            required: true,
            message: "Үйлчилгээний badge сонгоно уу",
            trigger: "change"
          }
        ],
        image: [
          {
            required: true,
            message: "Зурагын URI оруулна уу",
            trigger: "change"
          }
        ]
        // message: [
        //   {
        //     required: true,
        //     message: "Үйлчилгээний мсж оруулна уу",
        //     trigger: "change"
        //   }
        // ]
      }
    };
  },
  methods: {
    save() {
      this.$refs.service.validate(valid => {
        if (valid) {
          if (this.$route.name === "addService") {
            if (this.service.type === "link") this.service.route = "link";
            service.createService(this.service).then(response => {
              if (response.data.status === "success") {
                this.$notify({
                  title: "Амжилттай",
                  message: "Үйлчилгээ нэмэгдлээ",
                  type: "success"
                });
                this.$router.go(-1);
              } else if (!response.type) {
                this.$notify({
                  title: "Амжилтгүй",
                  message:
                    "Үйлчилгээ нэмэхэд алдаа гарлаа" +
                    " " +
                    response.error.message,
                  type: "warning"
                });
              }
            });
          }
        } else {
          this.$notify({
            title: "Анхааруулга",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    addPropertyRow() {
      this.service.properties.push({ name: "", value: "" });
    },
    deletePropertyRow(index) {
      this.service.properties.splice(index, 1);
    }
  }
};
</script>
<style>
.editField {
  margin-top: 25px;
}
</style>
